import React, { Component } from "react";
import { graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import grievanceStyle from "ps-assets/jss/grievanceStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { Helmet } from "react-helmet";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

var apiBaseUrl = process.env.API_URL;
let Url = process.env.BASE_URL;
// var table_data = [];
class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policy_no: ``,
      pan_no: ``,
      policy_holder_name: ``,
      dob: ``,
      customer_name: ``,
      address: ``,
      status: ``,
      response_data: [],
      details: {}
    };
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true
      },
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: `red`
          },
          paper: {
            boxShadow: `none`,
            paddingBottom: `20px`
          },
          responsiveScroll: { maxHeight: `auto !important` }
        },
        MUIDataTableHeadCell: {
          root: {
            fontSize: 17,
            color: `black`
          }
        }
      }
    });
  handleChange = e => {
    if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (
      e.target.id === `customer_name` ||
      e.target.id === `policy_holder_name`
    ) {
      if ((`nice`, e.target.value.search(`^[a-zA-Z. ']*$`) === 0)) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };
  handleSubmit = async e => {
    var rowDetials = [];
    e.preventDefault();
    if (
      this.state.policy_no == `` &&
      this.state.pan_no == `` &&
      this.state.policy_holder_name == `` &&
      this.state.address == `` &&
      this.state.dob == `` &&
      this.state.customer_name == ``
    ) {
      this.setState({
        status: `error`
      });
    } else {
      let url = `${apiBaseUrl}form/claim-proposal?`;

      let { policy_no, pan_no, policy_holder_name, address, dob } = this.state;

      var queryString = this.objToQueryString({
        policy_no: policy_no,
        pan_no: pan_no,
        policy_holder_name: policy_holder_name,
        address: address,
        dob: dob,
        pageNo: ` 1 `,
        totalRecord: ` 10 `
      });
      url += queryString;
      let res = await fetch(url);
      let resJson = await res.json();

      if (res.status !== 200) {
        this.setState({
          status: `fail`
        });
      } else if (resJson) {
        if (!resJson.error) {
          this.setState({
            policy_no: ``,
            pan_no: ``,
            policy_holder_name: ``,
            dob: ``,
            customer_name: ``,
            address: ``,
            status: `success`,
            response_data: resJson
          });
          resJson.success.map(data => {
            if (
              data.policy_no != `` &&
              data.customer_name != `` &&
              data.claims_id != `` &&
              data.unclaimed_premium_amount != undefined
            ) {
              var complete_data = {};
              complete_data[`policy_no`] = data.policy_no;
              complete_data[`customer_name`] = data.customer_name;
              complete_data[`claims_id`] = data.claims_id;
              complete_data[`unclaimed_premium_amount`] =
                data.unclaimed_premium_amount;
              rowDetials.push(complete_data);
            }
          });
          var details = {};
          details.rows = rowDetials;
          this.setState({
            details: details
          });
        } else {
          this.setState({
            status: `fail`
          });
        }
      }
    }
  };

  handleDate = value => {
    this.setState({ dob: value });
  };

  handleDateString = e => {
    var dob = e;
    if (this.state.dob.length > 10) {
      this.setState({
        dob: this.state.dob.substr(0, 10)
      });
    } else {
      this.setState({
        dob: dob
      });
    }
  };
  setStatus = () => {
    this.setState({
      status: ``
    });
  };
  objToQueryString = obj => {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj[key] && obj[key].trim() != ``)
        keyValuePairs.push(key + `=` + obj[key]);
    }
    return keyValuePairs.join(`&`);
  };

  render = () => {
    const columns = [
      {
        name: `S-no`,
        label: `S.No`,
        options: {
          filter: true,
          sort: true,
          style: { fontSize: 100 }
        }
      },
      {
        name: `product_name`,
        label: `Product Name`,
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: `uin`,
        label: `UIN`,
        options: {
          filter: true,
          sort: false
        }
      }
    ];
    const options = {
      selectableRows: false,
      filter: false,
      search: false,
      sort: false,
      download: false,
      print: false,
      viewColumns: false,
      responsive: `scroll`,
      pagination: false
    };
    let rows = [
      [``, `Young Star Insurance Policy`, `SHAHLIP22036V042122`],
      [
        ``,
        `Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd.,`,
        `SHAHLIP22027V032122`,
      ],
      [``, `Family Health Optima Insurance Plan`, `SHAHLIP23164V072223`],
      [``, `Star Micro Rural and Farmers Care`, `SHAHMIP22038V032122`],
      [``,`Senior Citizens Red Carpet Health Insurance Policy`,`SHAHLIP22199V062122`],
      [``, `Star Cardiac Care Insurance Policy`, `SHAHLIP22032V052122`],
      [``, `Diabetes Safe Insurance Policy`, `SHAHLIP23081V082223`],
      [``,`Star Comprehensive Insurance Policy`,`SHAHLIP22028V072122`],
      [``,`Medi Classic Insurance Policy (Individual)`,`SHAHLIP23037V072223`],
      [``,`Super Surplus Insurance Policy`,`SHAHLIP22035V062122`],
      [``,`Star Super Surplus (Floater) Insurance Policy`,`SHAHLIP22034V062122`],
      [
        ``,
        `Saral Suraksha Bima, Star Health And Allied Insurance Co Ltd`,
        `SHAPAIP22039V022122`
      ],
      [ ``,
        `Star Cardiac Care Insurance Policy-Platinum`,
        `SHAHLIP22033V022122`
      ],
      [ ``,
        `Star Cancer Care Platinum Insurance Policy`,
        `SHAHLIP22031V022122`
      ],
      [ ``,
        `Star Critical Illness Multipay Insurance Policy`,
        `SHAHLIP22140V012122`
      ],
      [ ``,
        `Star Women Care Insurance Policy`,
        `SHAHLIP23132V022223`
      ],
      [ ``,
        `Star Health Premier Insurance Policy`,
        `SHAHLIP22226V012122`
      ],
      [ ``,
        `Star Health Assure Insurance Policy`,
        `SHAHLIP23131V022223`
      ],
    ];

    
    rows = rows.map((a, key) => {
      a.shift();
      a.unshift(key + 1);
      return a;
    });

    const { classes, data } = this.props;
    var image =
      data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;
    return (
      <Layout image1={image} image2={image} small={true}>
        <Helmet key="helmetTags">
          <title>List of Products | StarHealth.in</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link
              rel="canonical"
              href={Url + `installment-products`}
            />
            <meta name="title" content="List of Products | StarHealth.in" />
            <meta name="twitter:title" content="List of Products | StarHealth.in" />
            <meta
              name="description"
              content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health"
            />
            <meta
              name="keywords"
              content="Star Health Insurance Plans,Insurance Policy,Star Health Products,Health Insurance Specialist in India, Star Health and Allied Insurance Company Limited, Chennai, India"
            />
            <meta property="og:title" content="List of Products | StarHealth.in" />
            <meta property="og:description" content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health" />
            <meta property="og:url" content={Url + `installment-products`}/>
            <meta name="List of Products | StarHealth.in" />
            <meta name="twitter:description" content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health" />
            <meta property="twitter:url" content={Url + `installment-products`} />
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: `100px` }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer className={classes.container}>
                <GridItem
                  md={9}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  {/* <h2 className={`${classes.title}`}>List of Products</h2> */}
                </GridItem>
                <GridItem
                  md={10}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  <div style={{ maxWidth: `100%`, paddingTop: `40px` }}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <MUIDataTable
                        data={rows}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  };
}

export default withStyles(grievanceStyle)(ListProducts);

ListProducts.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export const termsBenefit = graphql`
  query InstallmentProducts {
    allStrapiImages(filter: { category: { eq: "installment_products" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
